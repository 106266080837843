import { Card, CardBody, CardHeader, Code } from "@nextui-org/react";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { CardTitle, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { acceptInvitation } from "../../services/userService";
import favicon from '../logo.png';

export default function AcceptInvite({ userData, organizationId }) {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const auth = getAuth();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, userData.email, password);
            console.log('User created:', userCredential.user);
        } catch (creationError) {
            if (creationError.code === 'auth/email-already-in-use') {
                try {
                    const userCredential = await signInWithEmailAndPassword(auth, userData.email, password);
                    console.log('User logged in:', userCredential.user);
                } catch (loginError) {
                    setError(loginError.message);
                    return;
                }
            } else {
                setError(creationError.message);
                return;
            }
        }
        await acceptInvitation(userData._id, organizationId);
        navigate(`/orgs/${organizationId}/dashboard`);
    };

    return (
        <Card style={{
            width: '95%',
            maxWidth: '500px',
            margin: '20px auto',
            padding: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            textAlign: 'center'
        }}>
            <CardHeader>
                <img src={favicon} alt="Logo" style={{ width: '40px', marginBottom: '10px' }} />
                <CardTitle style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Accept Invite</CardTitle>
            </CardHeader>
            <CardBody>
                {error && <Code color="danger">{error}</Code>}
                <Form>
                    <Form.Group controlId="formPassword">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Password</Form.Label>
                        <input
                            placeholder="Enter password"
                            type="password"
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px'
                            }}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            disabled={false}
                        />
                    </Form.Group>
                    <button
                        style={{
                            width: '100%',
                            marginTop: '15px',
                            backgroundColor: '#444',
                            color: '#fff',
                            borderRadius: '8px',
                            padding: '10px',
                            border: 'none',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            transition: 'background-color 0.3s ease, transform 0.3s ease',
                            cursor: 'pointer'
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </Form>
            </CardBody>
        </Card>
    )
}
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { CardTitle, Col, Form, Row } from "react-bootstrap";
import { useRegistration } from "../../contexts/RegistrationContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createOrgAdmin } from "../../services/userService";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import favicon from '../logo.png';


export default function Overview() {
    const { registrationData } = useRegistration();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setLoading(true);
        setError('');

        const createOrgAdminData = {
            ...registrationData,
        };

        try {
            const response = await createOrgAdmin(createOrgAdminData);
            console.log(response);

            // Initialize Firebase Auth
            const auth = getAuth();
            // Create user with email and password in Firebase
            await createUserWithEmailAndPassword(
                auth,
                registrationData.email,
                registrationData.password // Make sure you have password in your registrationData
            );

            navigate(`/orgs/${response.organization._id}/dashboard`);
        } catch (error) {
            setError('Registration failed: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card style={{
            width: '95%',
            maxWidth: '400px',
            margin: '20px auto',
            padding: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            textAlign: 'center'
        }}>
            <CardHeader>
                <img src={favicon} alt="Logo" style={{ width: '40px', marginBottom: '10px' }} />
                <CardTitle style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Overview</CardTitle>
            </CardHeader>
            <CardBody>
                <Form>
                    <b>Organization Details</b>
                    <Form.Group controlId="formOrganizationName" style={{ marginBottom: '8px', marginTop: '8px' }}>
                        <input
                            placeholder="Enter organization name"
                            type="text"
                            value={registrationData?.organization_name}
                            disabled={true}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '8px',
                                backgroundColor: '#f5f5f5'
                            }}
                        />
                    </Form.Group>
                    <b>User Details</b>
                    <Form.Group controlId="formFirstName" style={{ marginBottom: '8px', marginTop: '8px' }}>
                        <input
                            placeholder="Enter first name"
                            type="text"
                            value={registrationData?.first_name}
                            disabled={true}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '8px',
                                backgroundColor: '#f5f5f5'
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formLastName" style={{ marginBottom: '8px' }}>
                        <input
                            placeholder="Enter last name"
                            type="text"
                            value={registrationData?.last_name}
                            disabled={true}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '8px',
                                backgroundColor: '#f5f5f5'
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formEmail" style={{ marginBottom: '8px', marginTop: '8px' }}>
                        <input
                            placeholder="Enter email"
                            type="email"
                            value={registrationData?.email}
                            disabled={true}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '8px',
                                backgroundColor: '#f5f5f5'
                            }}
                        />
                    </Form.Group>
                    <b>Billing Address</b>
                    <Form.Group controlId="country" style={{ marginTop: '8px' }}>
                        <input
                            type="text"
                            placeholder="Country"
                            value={registrationData?.country}
                            disabled={true}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '8px',
                                backgroundColor: '#f5f5f5'
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formAddress1" style={{ marginTop: '8px' }}>
                        <input
                            type="text"
                            placeholder="Address line 1"
                            value={registrationData?.address_line_1}
                            disabled={true}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '8px',
                                backgroundColor: '#f5f5f5'
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formAddress2" style={{ marginTop: '8px' }}>
                        <input
                            type="text"
                            placeholder="Address line 2"
                            value={registrationData?.address_line_2}
                            disabled={true}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '8px',
                                backgroundColor: '#f5f5f5'
                            }}
                        />
                    </Form.Group>
                    <Row style={{ marginTop: '8px' }}>
                        <Col>
                            <Form.Group controlId="formCity">
                                <input
                                    type="text"
                                    placeholder="City"
                                    value={registrationData?.city}
                                    disabled={true}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        marginBottom: '8px',
                                        backgroundColor: '#f5f5f5'
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formPostalCode">
                                <input
                                    type="text"
                                    placeholder="Postal code"
                                    value={registrationData?.postal_code}
                                    disabled={true}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        marginBottom: '8px',
                                        backgroundColor: '#f5f5f5'
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="formStateOrRegion" style={{ marginTop: '8px' }}>
                        <input
                            type="text"
                            placeholder="State or region"
                            value={registrationData?.state_or_region}
                            disabled={true}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '8px',
                                backgroundColor: '#f5f5f5'
                            }}
                        />
                    </Form.Group>
                    <button
                        style={{
                            width: '100%',
                            marginTop: '15px',
                            backgroundColor: '#444',
                            color: '#fff',
                            borderRadius: '8px',
                            padding: '10px',
                            border: 'none',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            transition: 'background-color 0.3s ease, transform 0.3s ease',
                            cursor: 'pointer'
                        }}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Register'}
                    </button>
                </Form>
            </CardBody>
        </Card>
    );
}
import { Card, CardBody, CardHeader, Code } from "@nextui-org/react";
import { useState } from "react";
import { CardTitle, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRegistration } from "../../contexts/RegistrationContext";
import favicon from '../logo.png';


export default function UserDetails() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { registrationData, updateRegistrationData } = useRegistration();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submit action
        setLoading(true);
        setError(''); // Clear any existing errors

        // Validation logic
        if (!firstName || !lastName || !password || !confirmPassword) {
            setError('Please fill in all fields.');
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        try {
            // Here you would typically handle the actual registration logic
            // For now, we'll just update the registration context
            updateRegistrationData({
                first_name: firstName,
                last_name: lastName,
                password,
            });

            // Navigate to the next page or show success message
            navigate('/register/billing-details');
        } catch (error) {
            // Handle errors, e.g., by setting an error state
            setError('An error occurred during registration.');
        } finally {
            setLoading(false); // Reset loading state regardless of outcome
        }
    };

    return (
        <Card style={{
            width: '95%',
            maxWidth: '500px',
            margin: '20px auto',
            padding: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            textAlign: 'center'
        }}>
            <CardHeader>
                <img src={favicon} alt="Logo" style={{ width: '40px', marginBottom: '10px' }} />
                <CardTitle style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Personal Details</CardTitle>
            </CardHeader>
            <CardBody>
                {error && <Code color="danger">{error}</Code>}
                <Form>
                    <Form.Group controlId="formFirstName">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>First Name</Form.Label>
                        <input
                            placeholder="Enter first name"
                            type="text"
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px'
                            }}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group controlId="formLastName">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Last Name</Form.Label>
                        <input
                            placeholder="Enter last name"
                            type="text"
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px'
                            }}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Email</Form.Label>
                        <input
                            placeholder="Enter email"
                            type="email"
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px',
                                backgroundColor: '#f5f5f5'
                            }}
                            value={registrationData?.email}
                            disabled={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Password</Form.Label>
                        <input
                            placeholder="Enter password"
                            type="password"
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px'
                            }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group controlId="formConfirmPassword">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Confirm Password</Form.Label>
                        <input
                            placeholder="Confirm password"
                            type="password"
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px'
                            }}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <button
                        style={{
                            width: '100%',
                            marginTop: '15px',
                            backgroundColor: '#444',
                            color: '#fff',
                            borderRadius: '8px',
                            padding: '10px',
                            border: 'none',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            transition: 'background-color 0.3s ease, transform 0.3s ease',
                            cursor: 'pointer'
                        }}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Next'}
                    </button>
                </Form>
            </CardBody>
        </Card>
    )
}
import { Card, CardBody, CardHeader, Code } from "@nextui-org/react";
import { useState } from "react";
import { CardTitle, Col, Form, Row } from "react-bootstrap";
import {
    CardElement, useElements, useStripe,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { validatePaymentMethod } from "../../services/billingService";
import { useRegistration } from "../../contexts/RegistrationContext";
import { getData } from 'country-list';
import favicon from '../logo.png';


export default function BillingDetails() {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [nameOnCard, setNameOnCard] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [stateOrRegion, setStateOrRegion] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const { registrationData, updateRegistrationData } = useRegistration();
    const [country, setCountry] = useState('US');
    const countries = getData();

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        // Check if all fields are populated
        if (!nameOnCard || !addressLine1 || !city || !postalCode || !stateOrRegion) {
            setError('Please fill in all required fields.');
            setLoading(false);
            return;
        }

        // Create a payment method
        const cardElement = elements.getElement(CardElement);
        const { paymentMethod, error: stripeError } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: nameOnCard,
                address: {
                    line1: addressLine1,
                    ...(addressLine2 !== '' && { line2: addressLine2 }),
                    city: city,
                    postal_code: postalCode,
                    state: stateOrRegion,
                },
            },
        });

        if (stripeError) {
            setError(stripeError.message);
            setLoading(false);
            return;
        }

        // Validate the payment method
        try {
            const validationResponse = await validatePaymentMethod(paymentMethod.id);
            if (!validationResponse.valid) {
                setError('Payment method is invalid.');
                setLoading(false);
                return;
            }
        } catch (error) {
            setError(error.message);
            setLoading(false);
            return;
        }

        // Update the registration object with the fields
        updateRegistrationData({
            payment_method_id: paymentMethod.id,
            address_line_1: addressLine1,
            ...(addressLine2 !== '' && { address_line_2: addressLine2 }),
            city: city,
            postal_code: postalCode,
            country: country,
            state_or_region: stateOrRegion,
        })

        // Navigate to the /register/overview page
        navigate('/register/overview');
        setLoading(false);
    };

    return (
        <Card style={{
            width: '95%',
            maxWidth: '400px',
            margin: '20px auto',
            padding: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            textAlign: 'center'
        }}>
            <CardHeader>
                <img src={favicon} alt="Logo" style={{ width: '40px', marginBottom: '10px' }} />
                <CardTitle style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Billing Details</CardTitle>
            </CardHeader>
            <p
                style={{
                    margin: '0 10px',
                    fontSize: '0.9rem',
                }}
            >Add your credit card details below. This card will be saved to your account and can be removed at any time.</p>
            <CardBody>
                {error && <Code color="danger">{error}</Code>}
                <Form>
                    <Form.Group controlId="formCardDetails">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '10px', fontSize: '0.9rem' }}>Card Details</Form.Label>
                        <div style={{
                            border: '1px solid #dee2e6',
                            borderRadius: '10px',
                            padding: '10px',
                            marginBottom: '10px',
                        }}>
                            <CardElement options={cardStyle} id="card-element" />
                        </div>
                    </Form.Group>
                    <Form.Group controlId="formNameOnCard"
                        style={{ marginBottom: '10px' }}
                    >
                        <input
                            type="text"
                            placeholder="Name on Card"
                            value={nameOnCard}
                            onChange={(e) => setNameOnCard(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px',
                                fontSize: '0.9rem'
                            }}
                        />
                    </Form.Group>
                    <b style={{ fontSize: '0.9rem' }}>Billing Address</b>
                    <Form.Group controlId="formCountry">
                        <select
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px',
                                fontSize: '0.9rem'
                            }}
                        >
                            {countries.map((country) => (
                                <option key={country.code} value={country.code}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </Form.Group>
                    <Form.Group
                        controlId="formAddress1"
                        style={{ marginTop: '10px' }}
                    >
                        <input
                            type="text"
                            placeholder="Address line 1"
                            value={addressLine1}
                            onChange={(e) => setAddressLine1(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px',
                                fontSize: '0.9rem'
                            }}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="formAddress2"
                        style={{ marginTop: '10px' }}
                    >
                        <input
                            type="text"
                            placeholder="Address line 2"
                            value={addressLine2}
                            onChange={(e) => setAddressLine2(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px',
                                fontSize: '0.9rem'
                            }}
                        />
                    </Form.Group>
                    <Row
                        style={{ marginTop: '10px' }}
                    >
                        <Col>
                            <Form.Group controlId="formCity">
                                <input
                                    type="text"
                                    placeholder="City"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        marginBottom: '10px',
                                        fontSize: '0.9rem'
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formPostalCode">
                                <input
                                    type="text"
                                    placeholder="Postal code"
                                    value={postalCode}
                                    onChange={(e) => setPostalCode(e.target.value)}
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        marginBottom: '10px',
                                        fontSize: '0.9rem'
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="formStateOrRegion"
                        style={{ marginTop: '10px' }}
                    >
                        <input
                            type="text"
                            placeholder="State or region"
                            value={stateOrRegion}
                            onChange={(e) => setStateOrRegion(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px',
                                fontSize: '0.9rem'
                            }}
                        />
                    </Form.Group>
                    <button
                        style={{
                            width: '100%',
                            marginTop: '20px',
                            backgroundColor: '#444',
                            color: '#fff',
                            borderRadius: '8px',
                            padding: '10px',
                            border: 'none',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            transition: 'background-color 0.3s ease, transform 0.3s ease',
                            cursor: 'pointer',
                            fontSize: '0.9rem'
                        }}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Next'}
                    </button>
                </Form>
            </CardBody>
        </Card>
    )
}
import { Avatar, Button, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Navbar, NavbarContent, NavbarItem, NavbarMenuToggle, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import SidePanel from "./SidePanel";
import { useNavigate } from "react-router-dom";
import CreateOrganizationModal from "./CreateOrganizationModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getBillingEntry } from "../services/billingService";

// Initialize Stripe with the publishable key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY);

/**
 * Navigation component that renders the top navigation bar.
 * It allows users to switch between organizations and access different parts of the application.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.userData - Data of the logged-in user.
 * @param {Array} props.organizations - List of organizations the user has access to.
 * @param {Object} props.selectedOrganization - Currently selected organization.
 * @param {Function} props.setSelectedOrganization - Function to set the selected organization.
 */
export default function Navigation({ userData, organizations, selectedOrganization, setSelectedOrganization }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    const { isOpen: isOrgCreateOpen, onOpen: onOrgCreateOpen, onClose: onOrgCreateClose } = useDisclosure();
    const [billingInfo, setBillingInfo] = useState(null);

    useEffect(() => {
        const fetchBillingEntry = async () => {
            try {
                const billingEntry = await getBillingEntry(selectedOrganization?._id);
                setBillingInfo(billingEntry); // Update to set billingInfo
            } catch (error) {
                console.error('Failed to fetch billing entry:', error);
            }
        };

        if (selectedOrganization?._id) {
            fetchBillingEntry();
        }
    }, [selectedOrganization]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Example threshold for mobile
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    /**
     * Retrieves the permission type for a user within a given organization.
     *
     * @param {Object} user - The user object containing permissions.
     * @param {string} organizationId - The ID of the organization to check.
     * @returns {string|null} - The permission type or null if not found.
     */
    function findPermissionTypeByOrganizationId(user, organizationId) {
        const permission = user.permission.find(perm => perm.organization_id === organizationId);
        return permission ? permission.type : null;
    }

    /**
     * Opens the modal to create a new organization.
     */
    const handleCreateOrganization = () => {
        onOrgCreateOpen();
    };

    /**
     * Handles the selection of an organization from the dropdown menu.
     * Updates the selected organization and navigates to the organization's dashboard.
     *
     * @param {string} organizationId - The ID of the selected organization.
     */
    const handleSelectOrganization = (organizationId) => {
        const organization = organizations.find(org => org._id === organizationId);
        setSelectedOrganization(organization);
        navigate(`/orgs/${organizationId}/dashboard`);
    };

    return (
        <Navbar onMenuOpenChange={setIsMenuOpen} maxWidth='full'>
            <NavbarContent>
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    className="sm:hidden"
                />
            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem>
                    <Avatar name={userData?.first_name[0] + userData?.last_name[0]} size="large" />
                </NavbarItem>
                <NavbarItem>
                    <Dropdown>
                        <DropdownTrigger>
                            <Button startContent={<svg className="leafygreen-ui-15wfe7w" height="14" width="14" role="presentation" aria-hidden="true" alt="" viewBox="0 0 16 16"><path fillRule="evenodd" clipRule="evenodd" d="M1 2C1 1.44772 1.44772 1 2 1H7C7.55228 1 8 1.44772 8 2V7.5V10.5V15H6V13H4V15H1V11H5.5C5.77614 11 6 10.7761 6 10.5C6 10.2239 5.77614 10 5.5 10H1V8H5.5C5.77614 8 6 7.77614 6 7.5C6 7.22386 5.77614 7 5.5 7H1V5H5.5C5.77614 5 6 4.77614 6 4.5C6 4.22386 5.77614 4 5.5 4H1V2ZM9 11H13.5C13.7761 11 14 10.7761 14 10.5C14 10.2239 13.7761 10 13.5 10H9V8H13.5C13.7761 8 14 7.77614 14 7.5C14 7.22386 13.7761 7 13.5 7H9V5C9 4.44772 9.44772 4 10 4H15C15.5523 4 16 4.44772 16 5V15H14V13H12V15H9V11Z" fill="currentColor"></path></svg>}>
                                {isMobile && selectedOrganization?.name.length > 25 
                                    ? `${selectedOrganization?.name.substring(0, 23)}...` 
                                    : selectedOrganization?.name}
                            </Button>
                        </DropdownTrigger>
                        {userData && (
                            <DropdownMenu aria-label="Organization Actions">
                                <DropdownSection showDivider>
                                    {userData.permission
                                        .filter(perm => !perm.invited)
                                        .map((perm) => (
                                            <DropdownItem key={perm.organization_id} onPress={() => handleSelectOrganization(perm.organization_id)}>
                                                {organizations.find(org => org._id === perm.organization_id)?.name || 'Unknown Organization'}
                                            </DropdownItem>
                                        ))
                                    }
                                </DropdownSection>
                                <DropdownItem key="create-org" onPress={handleCreateOrganization}>Create Organization</DropdownItem>
                            </DropdownMenu>
                        )}
                    </Dropdown>
                </NavbarItem>
            </NavbarContent>
            <SidePanel userType={findPermissionTypeByOrganizationId(userData, selectedOrganization?._id)} selectedOrganization={selectedOrganization} />
            <Elements stripe={stripePromise}>
                <CreateOrganizationModal
                    isOpen={isOrgCreateOpen} // Make sure this uses the state tied to the modal
                    onOpenChange={onOrgCreateClose} // This should be the function to close the modal
                    userEmail={userData.email}
                    billingInfo={billingInfo}
                />
            </Elements>
        </Navbar>
    );
}
import { Card, CardBody, CardHeader, Code } from "@nextui-org/react";
import { useState } from "react";
import { CardTitle, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRegistration } from "../../contexts/RegistrationContext";
import { getUserByEmail } from "../../services/userService";
import favicon from '../logo.png';

export default function StartPoint() {
    const [email, setEmail] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { registrationData, updateRegistrationData } = useRegistration();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        if (!email || !organizationName) {
            setError('Please fill in all fields.');
            setLoading(false);
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email.toLowerCase())) {
            setError('Please enter a valid email.');
            setLoading(false);
            return;
        }

        try {
            const existingUser = await getUserByEmail(email.toLowerCase());
            if (existingUser) {
                setError('A user with this email already exists.');
                setLoading(false);
                return;
            }

            updateRegistrationData({
                email: email.toLowerCase(),
                organization_name: organizationName
            });

            navigate('/register/user-details');
        } catch (error) {
            if (error.message.includes('404')) {
                updateRegistrationData({
                    email: email.toLowerCase(),
                    organization_name: organizationName
                });
                navigate('/register/user-details');
            } else {
                setError('An error occurred during registration.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card style={{
            width: '95%',
            maxWidth: '500px',
            margin: '20px auto',
            padding: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            textAlign: 'center'
        }}>
            <CardHeader>
                <img src={favicon} alt="Logo" style={{ width: '40px', marginBottom: '10px' }} />
                <CardTitle style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Start Point</CardTitle>
            </CardHeader>
            <CardBody>
                {error && <Code color="danger">{error}</Code>}
                <Form>
                    <Form.Group controlId="formOrganization">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Organization Name</Form.Label>
                        <input
                            placeholder="Enter organization name"
                            type="text"
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px'
                            }}
                            value={organizationName}
                            onChange={(e) => setOrganizationName(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Email</Form.Label>
                        <input
                            placeholder="Enter email"
                            type="email"
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                marginBottom: '10px'
                            }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <button
                        style={{
                            width: '100%',
                            marginTop: '15px',
                            backgroundColor: '#444',
                            color: '#fff',
                            borderRadius: '8px',
                            padding: '10px',
                            border: 'none',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            transition: 'background-color 0.3s ease, transform 0.3s ease',
                            cursor: 'pointer'
                        }}
                        onClick={(e) => handleSubmit(e)}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Next'}
                    </button>
                </Form>
            </CardBody>
        </Card>
    )
}
const API_URL = `${process.env.REACT_APP_API_URL}/email-addresses`;
const MESSAGE_API_URL = `${process.env.REACT_APP_API_URL}/email-messages`;

/**
 * Initiates the email verification process and saves the email address details to MongoDB.
 * 
 * @param {string} email_address The email address to be verified.
 * @param {string} organization_id The unique identifier of the organization.
 * @param {string} created_by The identifier of the user initiating the verification.
 * @returns {Promise<Object>} A promise that resolves to the result of the initiation process.
 */
async function initiateVerificationAndSave(email_address, organization_id, created_by) {
    const response = await fetch(`${API_URL}/verify-and-save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_address, organization_id, created_by })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Retrieves all email addresses and their verification statuses for a specific organization.
 * 
 * @param {string} organization_id The unique identifier of the organization.
 * @param {number} skip The number of records to skip for pagination.
 * @param {number} limit The number of records to return for pagination.
 * @param {string} search The search term to filter email addresses.
 * @returns {Promise<Object>} A promise that resolves to an object containing total count and a list of email addresses.
 */
async function getEmailAddressesByOrganization(organization_id, skip = 0, limit = 10, search = '') {
    const response = await fetch(`${API_URL}/organization/${organization_id}/email-addresses?skip=${skip}&limit=${limit}&search=${encodeURIComponent(search)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Retrieves all workmail email addresses for a specific organization.
 * 
 * @param {string} organization_id The unique identifier of the organization.
 * @returns {Promise<Array>} A promise that resolves to a list of email addresses.
 */
async function getWorkmailEmailAddressesByOrganization(organization_id) {
    const response = await fetch(`${API_URL}/organization/${organization_id}/workmail-email-addresses`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Verifies an email address for a given organization.
 * 
 * @param {string} organization_id The unique identifier of the organization.
 * @param {string} email_address The email address to be verified.
 * @returns {Promise<Object>} A promise that resolves to the result of the verification process.
 */
async function verifyEmailAddress(organization_id, email_address) {
    const response = await fetch(`${API_URL}/verify-email-address`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ organization_id, email_address })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Resends the verification email or re-initiates the SES verification process for an email address,
 * depending on its current verification status.
 * 
 * @param {string} email_address The email address for which to resend verification.
 * @param {string} organization_id The unique identifier of the organization to which the email address belongs.
 * @returns {Promise<Object>} A promise that resolves to the status of the resend operation.
 */
async function resendVerification(email_address, organization_id) {
    const response = await fetch(`${API_URL}/resend-verification`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_address, organization_id })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Searches for the availability of a domain name.
 * 
 * @param {string} domain_name The domain name to search for.
 * @returns {Promise<Object>} A promise that resolves to the availability status of the domain.
 */
async function searchDomain(domain_name, options = {}) {
    const { signal } = options;
    const response = await fetch(`${API_URL}/search-domain/${domain_name}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        signal: signal // Pass the signal to the fetch request
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Purchases a domain name.
 * 
 * @param {string} domain_name - The domain name to purchase.
 * @param {Object} registrant_contact - The registrant contact details.
 * @param {Object} admin_contact - The administrative contact details.
 * @param {Object} tech_contact - The technical contact details.
 * @param {string} organization_id - The unique identifier of the organization making the purchase.
 * @param {number} domain_price - The price of the domain.
 * @returns {Promise<Object>} A promise that resolves to the operation status and domain name.
 */
async function purchaseDomain(domain_name, registrant_contact, admin_contact, tech_contact, organization_id, domain_price) {
    const response = await fetch(`${API_URL}/purchase-domain`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain_name, registrant_contact, admin_contact, tech_contact, organization_id, domain_price })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Configures DKIM, SPF, and DMARC for a domain managed by AWS Route 53.
 * 
 * @param {string} domain_name The domain name to configure.
 * @param {string} hosted_zone_id The ID of the Route 53 hosted zone for the domain.
 * @param {string} spf_policy The SPF policy to apply.
 * @param {string} dmarc_policy The DMARC policy to apply.
 * @param {string} dmarc_reports_email The email address to receive DMARC reports.
 * @returns {Promise<Object>} A promise that resolves to the status of the configuration.
 */
async function configureEmailSecurity(domain_name, hosted_zone_id, spf_policy, dmarc_policy, dmarc_reports_email) {
    const response = await fetch(`${API_URL}/configure-email-security`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain_name, hosted_zone_id, spf_policy, dmarc_policy, dmarc_reports_email })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Retrieves all Route 53 domains tagged with a specific organization ID.
 * 
 * @param {string} organization_id The unique identifier of the organization.
 * @returns {Promise<Array>} A promise that resolves to a list of domain names.
 */
async function getDomainsByOrganization(organization_id, skip = 0, limit = 10, search = '') {
    const response = await fetch(`${API_URL}/organization/${organization_id}/domains?skip=${skip}&limit=${limit}&search=${encodeURIComponent(search)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Creates a WorkMail user in the specified organization.
 * 
 * @param {string} organization_id The ID of the WorkMail organization.
 * @param {string} user_name The username for the new user.
 * @param {string} display_name The display name for the new user.
 * @param {string} password The password for the new user.
 * @param {string} created_by The identifier of the user creating the WorkMail user.
 * @param {string} domain_name The domain name associated with the WorkMail user.
 * @returns {Promise<Object>} A promise that resolves to the WorkMail user ID.
 */
async function createWorkmailUser(organization_id, user_name, display_name, password, created_by, domain_name) {
    const response = await fetch(`${API_URL}/create-workmail-user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ organization_id, user_name, display_name, password, created_by, domain_name })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Retrieves emails from each WorkMail inbox provided in the credentials list.
 * 
 * @param {Array<Object>} credentials A list of dictionaries containing email address, password, IMAP host, and IMAP port.
 * @param {number} page The page number to retrieve.
 * @param {number} perPage The number of emails to retrieve per page.
 * @returns {Promise<Array<Object>>} A promise that resolves to a list of email details.
 */
async function getEmailsFromInboxes(credentials, page = 1, perPage = 10) {
    const response = await fetch(`${API_URL}/get-emails-from-inboxes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ credentials, page, perPage })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Retrieves paginated email messages for WorkMail users in a specific organization.
 * 
 * @param {string} organization_id The unique identifier of the organization.
 * @param {number} page The page number to retrieve.
 * @param {number} perPage The number of emails to retrieve per page.
 * @returns {Promise<Object>} A promise that resolves to an object containing email messages and total message count.
 */
async function getWorkmailEmailMessages(organization_id, page = 1, perPage = 10, searchQuery = "") {
    const response = await fetch(`${MESSAGE_API_URL}/workmail-email-messages?organization_id=${organization_id}&page=${page}&per_page=${perPage}&search=${encodeURIComponent(searchQuery)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Retrieves paginated email messages for WorkMail users in a specific organization.
 * 
 * @param {string} organization_id The unique identifier of the organization.
 * @param {number} page The page number to retrieve.
 * @param {number} perPage The number of emails to retrieve per page.
 * @returns {Promise<Object>} A promise that resolves to an object containing email messages and total message count.
 */
async function getEmailMessages(organization_id, page = 1, perPage = 10) {
    const response = await fetch(`${MESSAGE_API_URL}/query-email-messages?organization_id=${organization_id}&page=${page}&per_page=${perPage}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Retrieves filtered email addresses for a specific organization.
 * Filters out email addresses that don't have a workmail_user_id or have a created_date less than two weeks ago.
 * 
 * @param {string} organization_id The unique identifier of the organization.
 * @returns {Promise<Array>} A promise that resolves to a list of filtered email addresses.
 */
async function getFilteredEmailAddressesByOrganization(organization_id) {
    const response = await fetch(`${API_URL}/organization/${organization_id}/filtered-email-addresses`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Sends an email message using WorkMail.
 * 
 * @param {Object} emailData The email data including email_address, subject, body, recipient, organization_id, smtp_host, smtp_port.
 * @returns {Promise<Object>} A promise that resolves to the result of the send operation.
 */
async function sendWorkmailEmailMessage(emailData) {
    const response = await fetch(`${MESSAGE_API_URL}/send-workmail-email-message`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData)
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Deletes an email message by its unique identifier.
 * 
 * @param {string} messageId The unique identifier of the email message to be deleted.
 * @returns {Promise<Object>} A promise that resolves to the result of the delete operation.
 */
async function deleteEmailMessageById(messageId) {
    const response = await fetch(`${MESSAGE_API_URL}/email_message/delete/${messageId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

// Add this function to the export statement
export {
    initiateVerificationAndSave,
    getEmailAddressesByOrganization,
    getWorkmailEmailAddressesByOrganization,
    verifyEmailAddress,
    resendVerification,
    searchDomain,
    purchaseDomain,
    configureEmailSecurity,
    getDomainsByOrganization,
    createWorkmailUser,
    getEmailsFromInboxes,
    getWorkmailEmailMessages,
    getFilteredEmailAddressesByOrganization,
    sendWorkmailEmailMessage,
    deleteEmailMessageById,
    getEmailMessages
};
import React, { useState, useEffect } from 'react';
import favicon from './logo.png';

/**
 * Landing component for the homepage.
 * Displays a navigation bar and a hero section.
 */
const Landing = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);

    useEffect(() => {
        const handleMouseLeave = (e) => {
            // Check if mouse moves to top of viewport
            if (e.clientY <= 0) {
                const lastShown = localStorage.getItem('exitModalLastShown');
                const now = new Date().getTime();

                if (!lastShown || (now - parseInt(lastShown)) > 5 * 60 * 1000) {
                    setShowExitModal(true);
                    localStorage.setItem('exitModalLastShown', now.toString());
                }
            }
        };

        document.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            document.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    // Styles for the Landing page components
    const styles = {
        navBar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 20px',
            backgroundColor: '#fff',
            color: '#000',
            height: '80px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            transition: 'background-color 0.3s ease',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
        },
        navLinks: {
            display: menuOpen ? 'flex' : 'none',
            flexDirection: 'column',
            listStyle: 'none',
            gap: '20px',
            position: 'absolute',
            top: '80px',
            right: '0',
            backgroundColor: '#fff',
            padding: '10px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        },
        navLink: {
            textDecoration: 'none',
            color: '#000',
            fontSize: '1rem',
            fontWeight: 'bold',
            padding: '10px 15px',
            borderRadius: '5px',
            transition: 'background-color 0.3s ease',
        },
        logo: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: '#000',
            fontSize: '24px',
            fontWeight: 'bold',
        },
        icon: {
            width: '32px',
            height: '32px',
            marginRight: '8px',
        },
        menuToggle: {
            display: 'block',
            cursor: 'pointer',
            fontSize: '1.5rem',
            color: '#000',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            textAlign: 'center',
            padding: 0,
            backgroundColor: '#fff',
        },
        heroSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100vh',
            width: '100%',
            padding: '0 5%',
            color: '#222',
            background: 'linear-gradient(to right, rgba(200, 200, 200, 0.2), rgba(200, 200, 200, 0.4))',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)',
            borderRadius: '0',
            position: 'relative',
            overflow: 'hidden',
        },
        textContainer: {
            flex: '1',
            textAlign: 'left',
            zIndex: 1,
        },
        heroImageContainer: {
            flex: '1',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative',
        },
        heroImage: {
            width: '85%',
            height: 'auto',
            boxShadow: '0 12px 24px rgba(0, 0, 0, 0.3)',
            borderRadius: '16px',
            position: 'relative',
            zIndex: 1,
        },
        heroImageSecondary: {
            width: '20%',
            height: 'auto',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
            position: 'absolute',
            right: '60%', // Moved further to the left
            top: '60%', // Moved down more
            zIndex: 2,
            transform: 'translateX(-50%)',
        },
        heroHeader: {
            fontSize: '3.5rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            color: '#222',
            lineHeight: '1.2',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        heroSubHeader: {
            fontSize: '1.5rem',
            maxWidth: '600px',
            marginBottom: '2rem',
            color: '#333',
            lineHeight: '1.5',
        },
        heroButton: {
            padding: '14px 28px',
            fontSize: '1.2rem',
            backgroundColor: '#444',
            color: '#fff',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            textDecoration: 'none',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
        },
        // Added styles for mobile hero
        mobileHeroSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: '100%',
            color: '#222',
            textAlign: 'center',
            background: 'linear-gradient(to right, rgba(200, 200, 200, 0.1), rgba(200, 200, 200, 0.3))',
            boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '0',
            padding: '20px',
        },
        mobileHeroHeader: {
            fontSize: '2.5rem', // Increased for better readability
            fontWeight: 'bold',
            marginBottom: '1rem',
            color: '#222',
            textAlign: 'center',
            lineHeight: '1.3',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
        },
        mobileHeroSubHeader: {
            fontSize: '1.2rem', // Increased for better readability
            maxWidth: '90%',
            marginBottom: '1.5rem',
            color: '#333',
            lineHeight: '1.5',
            textAlign: 'center',
            margin: '0 auto',
        },
        mobileHeroButton: {
            padding: '14px 28px', // Adjusted for better fit
            fontSize: '1.2rem', // Increased for better readability
            backgroundColor: '#333',
            color: '#fff',
            border: 'none',
            borderRadius: '10px',
            cursor: 'pointer',
            textDecoration: 'none',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            margin: '25px auto 0 auto',
        },
        footerLink: {
            color: '#fff',
            textDecoration: 'none',
            fontSize: '1.1rem', // Slightly increased font size
            transition: 'color 0.3s ease, transform 0.3s ease', // Added transform transition
            '&:hover': {
                color: '#ddd',
                transform: 'scale(1.1)', // Slightly larger scale on hover
            },
        },
        footerTrademark: {
            fontSize: '0.9rem',
            color: '#aaa',
            textAlign: 'center',
            marginTop: '30px',
            padding: '10px 0',
            borderTop: '1px solid #333',
            width: '100%',
            backgroundColor: '#111',
            transition: 'color 0.3s ease',
        },
        mobileFooter: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            backgroundColor: '#111',
            color: '#fff',
            width: '100%',
            textAlign: 'center',
            boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.3)',
            borderTop: '1px solid #333',
        },
        mobileFooterSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            margin: '10px 0',
        },
        desktopFooter: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '50px 80px',
            backgroundColor: '#111',
            color: '#fff',
            width: '100%',
            textAlign: 'center',
            flexWrap: 'wrap',
            boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.3)',
            borderTop: '1px solid #333',
        },
        desktopFooterSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '15px',
            margin: '15px 30px',
        },
    };

    const modalStyles = {
        modal: {
            display: showExitModal ? 'flex' : 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2000,
        },
        modalContent: {
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '12px',
            maxWidth: '500px',
            width: '90%',
            position: 'relative',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
        },
        closeButton: {
            position: 'absolute',
            top: '15px',
            right: '15px',
            border: 'none',
            background: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            color: '#666',
        },
        modalHeader: {
            fontSize: '24px',
            marginBottom: '15px',
            color: '#222',
        },
        modalText: {
            marginBottom: '20px',
            color: '#444',
            lineHeight: '1.5',
        },
        modalButton: {
            padding: '12px 24px',
            backgroundColor: '#000',
            color: '#fff',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s ease',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '100%',
        },
        input: {
            padding: '12px',
            borderRadius: '6px',
            border: '1px solid #ddd',
            fontSize: '16px',
            width: '100%',
            boxSizing: 'border-box',
            transition: 'border-color 0.3s ease',
            '&:focus': {
                outline: 'none',
                borderColor: '#000',
            },
        },
        modalButton: {
            padding: '12px 24px',
            backgroundColor: '#000',
            color: '#fff',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s ease',
            width: '100%',
            marginTop: '10px',
            '&:hover': {
                backgroundColor: '#333',
            },
        },
        modalContent: {
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '12px',
            maxWidth: '400px', // Slightly reduced for better form appearance
            width: '90%',
            position: 'relative',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
        },
        modalHeader: {
            fontSize: '24px',
            marginBottom: '15px',
            color: '#222',
            textAlign: 'center',
        },
        modalText: {
            marginBottom: '20px',
            color: '#444',
            lineHeight: '1.5',
            textAlign: 'center',
        },
    };
    /**
     * Renders the mobile menu.
     * @returns {JSX.Element} The mobile menu component.
     */
    const renderMobileMenu = () => (
        <>
            <div style={styles.menuToggle} onClick={() => setMenuOpen(!menuOpen)}>
                ☰
            </div>
            <ul style={{
                ...styles.navLinks,
                display: menuOpen ? 'flex' : 'none',
                flexDirection: 'column',
                gap: '15px',
                right: '15px',
                top: '70px',
                borderRadius: '10px',
                zIndex: 1000,
                padding: '15px',
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            }}>
                <li><a href="#pricing" style={styles.navLink}>Pricing</a></li>
                <li><a href="#resources" style={styles.navLink}>Resources</a></li>
                <li><a href="https://withcold.com/login" style={styles.navLink}>Sign in</a></li>
                <li>
                    <a href="https://withcold.com/register/start-point"
                        style={{
                            ...styles.navLink,
                            backgroundColor: '#000',
                            color: '#fff',
                            padding: '12px 25px',
                            borderRadius: '8px',
                            boxShadow: '0 3px 6px rgba(0,0,0,0.3)',
                            transition: 'background-color 0.3s ease, transform 0.3s ease'
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = '#333'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = '#000'}
                    >
                        Try for Free →
                    </a>
                </li>
            </ul>
        </>
    );

    /**
     * Renders the desktop menu.
     * @returns {JSX.Element} The desktop menu component.
     */
    const renderDesktopMenu = () => (
        <ul style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            listStyle: 'none',
            margin: 0,
            padding: 0
        }}>
            <li><a href="#pricing" style={styles.navLink}>Pricing</a></li>
            <li><a href="#resources" style={styles.navLink}>Resources</a></li>
            <li><a href="https://withcold.com/login" style={styles.navLink}>Sign in</a></li>
            <li>
                <a href="https://withcold.com/register/start-point"
                    style={{
                        ...styles.navLink,
                        backgroundColor: '#000',
                        color: '#fff',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        transition: 'background-color 0.3s ease, transform 0.3s ease'
                    }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = '#333'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = '#000'}
                >
                    Try for Free →
                </a>
            </li>
        </ul>
    );

    const ExitModal = () => {
        const [formData, setFormData] = useState({ name: '', email: '' });

        const handleSubmit = (e) => {
            e.preventDefault();
            // Here you would typically handle the form submission
            // For example, sending to an API endpoint
            console.log('Form submitted:', formData);
            setShowExitModal(false);
            localStorage.setItem('exitModalShown', 'true');
        };

        const handleChange = (e) => {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        };

        return (
            <div style={modalStyles.modal}>
                <div style={modalStyles.modalContent}>
                    <button
                        style={modalStyles.closeButton}
                        onClick={() => setShowExitModal(false)}
                    >
                        ×
                    </button>
                    <h2 style={modalStyles.modalHeader}>Stay in the Loop!</h2>
                    <p style={modalStyles.modalText}>
                        Subscribe to receive product updates and exclusive discounts.
                    </p>
                    <form onSubmit={handleSubmit} style={modalStyles.form}>
                        <input
                            type="text"
                            name="name"
                            placeholder="John Smith"
                            value={formData.name}
                            onChange={handleChange}
                            style={modalStyles.input}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="john.smith@example.com"
                            value={formData.email}
                            onChange={handleChange}
                            style={modalStyles.input}
                            required
                        />
                        <button
                            type="submit"
                            style={modalStyles.modalButton}
                        >
                            Subscribe Now
                        </button>
                    </form>
                </div>
            </div>
        );
    };

    const renderMobileFooter = () => (
        <footer style={styles.mobileFooter}>
            <div style={styles.mobileFooterSection}>
                <a href="#blog" style={styles.footerLink}>Blog</a>
                <a href="#guide" style={styles.footerLink}>Guide</a>
            </div>
            <div style={styles.mobileFooterSection}>
                <a href="#pricing" style={styles.footerLink}>Pricing</a>
                <a href="#resources" style={styles.footerLink}>Resources</a>
            </div>
            <div style={styles.mobileFooterSection}>
                <a href="#affiliates" style={styles.footerLink}>Affiliates</a>
                <a href="#contact" style={styles.footerLink}>Contact</a>
            </div>
            <div style={styles.footerTrademark}>
                <p>© 2024 Withcold. All rights reserved.</p>
            </div>
        </footer>
    );

    /**
     * Renders the desktop footer.
     * @returns {JSX.Element} The desktop footer component.
     */
    const renderDesktopFooter = () => (
        <footer style={styles.desktopFooter}>
            <div style={styles.desktopFooterSection}>
                <a href="#blog" style={styles.footerLink}>Blog</a>
                <a href="#guide" style={styles.footerLink}>Guide</a>
            </div>
            <div style={styles.desktopFooterSection}>
                <a href="#pricing" style={styles.footerLink}>Pricing</a>
                <a href="#resources" style={styles.footerLink}>Resources</a>
            </div>
            <div style={styles.desktopFooterSection}>
                <a href="#affiliates" style={styles.footerLink}>Affiliates</a>
                <a href="#contact" style={styles.footerLink}>Contact</a>
            </div>
            <div style={styles.footerTrademark}>
                <p>© 2024 Withcold. All rights reserved.</p>
            </div>
        </footer>
    );

    return (
        <>
            <nav style={styles.navBar}>
                <a href="/" style={styles.logo}>
                    <img src={favicon} alt="Logo" style={styles.icon} />
                    withcold
                </a>
                {window.innerWidth < 768 ? renderMobileMenu() : renderDesktopMenu()}
            </nav>
            <div style={window.innerWidth < 768 ? styles.mobileContainer : styles.container}>
                <div style={window.innerWidth < 768 ? styles.mobileHeroSection : styles.heroSection}>
                    <div style={window.innerWidth > 768 ? styles.textContainer : {}}>
                        <h1 style={window.innerWidth < 768 ? styles.mobileHeroHeader : styles.heroHeader}>
                            Cold email infrastructure for scale
                        </h1>
                        <p style={window.innerWidth < 768 ? styles.mobileHeroSubHeader : styles.heroSubHeader}>
                            Launch and scale cold email with ease. Pay only for what you use, keep your campaigns and teams organized, and monitor key metrics.
                        </p>
                        <button onClick={() => window.location.href = "https://withcold.com/register/start-point"} style={window.innerWidth < 768 ? styles.mobileHeroButton : styles.heroButton}>
                            Get Started Today
                        </button>
                    </div>
                    {window.innerWidth > 768 && (
                        <div style={styles.heroImageContainer}>
                            <img
                                src="https://paraform-recruiting.s3.amazonaws.com/Screenshot%202024-11-07%20at%202.34.56%20PM.png"
                                alt="Hero Image 1"
                                style={styles.heroImage}
                            />
                            <img
                                src="https://paraform-recruiting.s3.amazonaws.com/domains.png"
                                alt="Hero Image 2"
                                style={styles.heroImageSecondary}
                            />
                        </div>
                    )}
                </div>
            </div>
            {window.innerWidth < 768 ? renderMobileFooter() : renderDesktopFooter()}
            <ExitModal />
        </>
    );
};

export default Landing;
